.TourContent {
  display: flex;
  z-index: 3;
  width: 100%;
  height: 800px;
  position: relative;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(0deg, #b3b3b3, #666);
}

.TourDatesImage {
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  width: 60%;
  height: 100%;
  overflow: hidden;
}

.imgTour {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.TourDatesContent {
  width: 40%;
  margin-top: 120px;

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin-top: 30px;
  }
}

.TourDatesHeader {
  color: #1c1c1c;
  font-size: 65px;
  text-align: center;
}

.TourDatesContainer {
  height: 500px;
  margin-top: 70px;
  display: flex;
  justify-content: space-around;
}

.TourBox {
  height: 300px;
  width: 300px;
  border-style: solid;
  border-color: black;
  border-width: 0px;
  border-radius: 5px;
  background-color: #b3b3b3;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;  justify-content: center;
}

.TourBoxHeader {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.LocAndDate {
  margin-left: 10%;
}

.LocAndDate p {
  margin-bottom: 10px;
  font-size: 20px;
}

.DateFlex {
  display: flex;
}

.Location {
  margin-top: 4px;
  margin-right: 5px;
}

.Date {
  margin-top: 4px;
  margin-right: 5px;
}

@media screen and (max-width: 1600px) {
    .TourDatesContainer {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .TourDatesContent {
        margin-top: 0px;
    }

    .TourBox {
        margin-bottom: 50px;
    }

    .TourDatesHeader {
        margin-bottom: 120px;
    }

    .slide_button {
      margin-top: 15px;
    }
}

@media screen and (max-width: 1000px) {
  .TourContent {
    flex-direction: column;
    height: 1200px;
  }

  .TourDatesImage {
    width: 100%;
    height: 40%;
    margin-top: 30px;
  }

  .TourDatesContent {
    width: 100%;
    margin-top: 20px;
  }

  .TourDatesContainer {
    height: 400px;
    margin-bottom: 100px;
  }

  .TourDatesHeader {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 700px) {
    .TourDatesContainer {
        flex-direction: column;
        align-items: center;
    }
}