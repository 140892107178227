@font-face {
  font-family: 'good timing bd';
  src: local('good timing bd'), url(./font/good\ timing\ bd.ttf) format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "good timing bd", sans-serif;
}