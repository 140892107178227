.slider_container {
    background: #b3b3b3;
    height: 800px;

}

.slider {
    position: relative;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 800px;
    height: 650px;
    margin-right: 10px;
}

.right_arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: black;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left_arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: black;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.slide_button {
  border-radius: 50px;
  background: #1e2121;
  white-space: nowrap;
  padding: 14px 48px;
  color: #fff;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin: auto;
}

.slide_button:hover {
  transition: all 0.2s ease-in-out;
  background: #595959;
}

@media screen and (max-width: 1500px) {
    .slider {
        height: 600px;
    }

    .slider_container {
        height: 700px;
    }
    
    .image {
        width: 600px;
        height: 500px;
    }
}

@media screen and (max-width: 1000px) {
    .slider {
        height: 500px;
    }

    .slider_container {
        height: 600px;
    }
    
    .image {
        width: 500px;
        height: 400px;
    }
}

@media screen and (max-width: 500px) {
    .slider {
        height: 450px;
    }

    .slider_container {
        height: 550px;
    }
    
    .image {
        width: 400px;
        height: 300px;
    }
}

@media screen and (max-width: 400px) {
    .slider {
        height: 450px;
    }

    .slider_container {
        height: 550px;
    }
    
    .image {
        width: 350px;
        height: 280px;
    }
}   